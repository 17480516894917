import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Modal from 'react-modal';
import { Provider, Chain, useConnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { getDefaultProvider, providers } from 'ethers';

function MyApp({ Component, pageProps }: AppProps) {
  Modal.setAppElement("#__next")

  const bscTestnet: Chain = {
    id: 97,
    name: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorers: [{
        name: 'Binance Chain Explorer (Testnet)',
        url: 'https://testnet-explorer.binance.org/',
    }],
    testnet: true,
  }

  const bscMainnet: Chain = {
    id: 56,
    name: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorers: [{
        name: 'Binance Chain Explorer',
        url: 'https://explorer.binance.org/',
    }],
  }

  const rpcUrl = {
    56: 'https://bsc-dataseed.binance.org/',
    97: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
  } as {[key: number]: string}

  const connectors = () => {
    const chains: Chain[] = [bscTestnet];

    return [
      new InjectedConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
          rpc: rpcUrl,
          chainId: 97,
        },
      })
    ]
  }

  return (
    <Provider
      connectors={connectors}
      autoConnect={true}
      provider={({chainId}) => {
        return chainId ?
        new providers.JsonRpcProvider(rpcUrl[chainId]!) :
        getDefaultProvider(rpcUrl[97]);
        // TODO: will remove this comonent in the production version
        // getDefaultProvider(process.env.NODE_ENV !== 'production' ? rpcUrl[97] : rpcUrl[56]);
      }} >
      <Component {...pageProps} />
    </Provider>
  );
};

export default MyApp;
